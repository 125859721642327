<template>
  <div class="main-view-box">
  <div :id="containerId" v-if="downloaded" />
  <div class="placeholder" v-else>
    Downloading ...
  </div>
  <div class="earth-view-box" id="earth-view-box" v-show="show_earth_view"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-gl';
import bg4 from '@/game/assets/html/earth/images/bg4.jpg'

export default {
  data() {
    return {
      downloaded: false,
      show_earth_view: true,
      gameInstance: null,
      containerId: 'game-container',
      sceneName: '',
    }
  },
  async mounted() {
    const game = await import(/* webpackChunkName: "game" */ '@/game/game')
    let that = this
    this.downloaded = true
    this.$nextTick(() => {
      let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight; 

      if (w < 300) {
        w = 300;
      }
      if (h < 200) {
        h = 200
      }

      console.log("window", w, h);

      window.gameWidth = w
      window.gameHeight = h
      that.gameInstance = game.launch(that.containerId, w, h)
      that.gameInstance.registry.events.on('changedata', that.changeData, that)
      that.drawEarth();
    });
  },
  methods: {
    changeData: function(parent, key, data) {
      // console.log("vue changeData ==>", parent, key, data)
      if (key == 'sceneName')  {
        this.sceneName = data.name;
        if(data.show_earth_view == 2) {
          this.show_earth_view = false;
        } else {
          this.show_earth_view = true;
        }
      }
    },
    drawEarth: function() {
      // 获取元素
      let dom = document.getElementById("earth-view-box");
      // 初始化echarts
      let myChart = echarts.init(dom);

      // 地球数据显示
      let ds = [/*{
          name: '中国',
          point: [116.46, 39.92, 0],
          itemStyleColor: '#f00',
          labelText: '中国'
      }, {
          name: '印度',
          point: [78.96288, 20.593684, 0],
          itemStyleColor: '#99CC66',
          labelText: '印度'
      }, {
          name: '韓國',
          point: [127.766922, 35.907757, 0],
          itemStyleColor: '#9999FF',
          labelText: '韓國'
      }, {
          name: '蒙古',
          point: [103.846656, 46.862496, 0],
          itemStyleColor: '#339966',
          labelText: '蒙古'
      }, {
          name: '越南',
          point: [108.277199, 14.058324, 0],
          itemStyleColor: '#993366',
          labelText: '越南'
      }, {
          name: '老挝',
          point: [102.495496, 19.85627, 0],
          itemStyleColor: '#996666',
          labelText: '老挝'
      }, {
          name: '柬埔寨',
          point: [104.990963, 12.565679, 0],
          itemStyleColor: '#66CCFF',
          labelText: '柬埔寨'
      }, {
          name: '缅甸',
          point: [95.956223, 21.913965, 0],
          itemStyleColor: '#666666',
          labelText: '缅甸'
      }*/]
    
      // 点配置信息
      let series = ds.map(item => {
          return {
              name: item.name, // 是否显示左上角图例
              type: 'scatter3D',
              coordinateSystem: 'globe',
              blendMode: 'lighter',
              symbolSize: 12, // 点位大小
    
              itemStyle: {
                  color: item.itemStyleColor, // 各个点位的颜色设置
                  opacity: 1, // 透明度
                  borderWidth: 0, // 边框宽度
              },
              label: {
                  show: true, // 是否显示字体
                  position: 'left', // 字体位置。top、left、right、bottom
                  formatter: item.labelText, // 具体显示的值
                  textStyle: {
                      color: '#000', // 字体颜色
                      borderWidth: 0, // 字体边框宽度
                      fontSize: 14, // 字体大小
                  }
              },
              data: [item.point] // 数据来源
          }
      });
    
      // 添加上面的配置项到地球上
      myChart.setOption({
          // 图例设置
          
          // 地球背景色
          // 地球参数设置
          globe: {
              baseTexture: bg4, // 地球表面覆盖的图片,可以替换成自己想要的图片
              // baseTexture: '/assets/html/earth/images/bg4.jpg',
              shading: 'color', // 地球中三维图形的着色效果
              viewControl: {
                  autoRotate: true, // 是否开启视角绕物体的自动旋转查看
                  autoRotateSpeed: 3, //物体自转的速度,单位为角度 / 秒，默认为10 ，也就是36秒转一圈。
                  autoRotateAfterStill: 2, // 在鼠标静止操作后恢复自动旋转的时间间隔,默认 3s
                  rotateSensitivity: 1, // 旋转操作的灵敏度，值越大越灵敏.设置为0后无法旋转。[1, 0]只能横向旋转.[0, 1]只能纵向旋转
                  targetCoord: [122.46, 20.92], // 定位到北京
                  maxDistance: 150,
                  minDistance: 150
              }
          },
          // 地球文字显示信息配置
          series: series
      })
    }
  },
  destroyed() {
    this.gameInstance.destroy(false)
  }
}
</script>

<style lang="scss" scoped>
.placeholder {
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
.main-view-box {
  display: block;
  position: relative;
}
.earth-view-box {
  position: fixed;
  overflow: hidden;
  left: 0px;
  top: 0px;
  width: 150px;
  height: 150px;
  background-color: rgba(255, 248, 255, 0);
  border-radius: 50%;
}
</style>
